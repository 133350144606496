/*eslint-disable */
// import GPathCareerUnivModel from '../../Model/GPathCareerUniv'
import request from '../../Utils/curl'

let GPathCareerUnivs = {
  /**
   * gpath_career_univList
   */
  async gpath_career_univList (context, whereFilter=null) {
    try{
      let post_data = new FormData();  
      if (whereFilter) {
        post_data.append('filter', JSON.stringify(whereFilter))
      }

      return await request.curl(context, "gpath_career_univ_list", post_data)
        .then(async response => {
          return response
      });

    }
    catch(err){
      console.error('Exception occurred at gpath_career_univList() and  Exception:',err.message)
    }
  },

  /**
   * gpath_career_univ_countryList
  */
  async gpath_career_univ_countryList (context, whereFilter=null) {
    try{
      let post_data = new FormData();  
      if (whereFilter) {
        post_data.append('filter', JSON.stringify(whereFilter))
      }

      return await request.curl(context, "gpath_career_univ_country_list", post_data)
        .then(async response => {
          return response
      });

    }
    catch(err){
      console.error('Exception occurred at gpath_career_univ_country_list() and  Exception:',err.message)
    }
  },
    
  /**
   * gpath_career_univView
   */
  async gpath_career_univView (context, gpathCareerUnivId) {
    try {      
      let post_data = new FormData();
      post_data.append('gcu_id', gpathCareerUnivId);
      return await request.curl(context, "gpath_career_univ_view", post_data)
      .then(async response => {
        return response;
      });
    } 
    catch (err) {
      console.error("Exception occurred at gpath_career_univView() and Exception:",err.message)
    }    
  },

  /**
   * gpath_career_univAdd
   */
  async gpath_career_univAdd (context, gpath_career_univObj) {
    try{
      let post_data = new FormData();
    
      for (let key in gpath_career_univObj) {
        post_data.append(key, gpath_career_univObj[key]);
      }

      return await request.curl(context, "gpath_career_univ_add", post_data)
      .then(async response => {
          return response;
      });
    }
    catch(err){
      console.error('Exception occurred at gpath_career_univAdd() and Exception:',err.message)
    }
  },

  /**
   * gpath_career_univEdit
   */
  async gpath_career_univEdit (context, gpath_career_univObj) {
    try{
      let post_data = new FormData();
    
      for (let key in gpath_career_univObj) {
        post_data.append(key, gpath_career_univObj[key]);
      }

      return await request.curl(context, "gpath_career_univ_edit", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.error('Exception occurred at gpath_career_univEdit() and Exception:',err.message)
    }
  },

  /**
   * gpath_career_univDelete
   */
  async gpath_career_univDelete (context, gpathCareerUnivId) {
    try{
      let post_data = new FormData();
      
      post_data.append('gcu_id', gpathCareerUnivId);

      return await request.curl(context, "gpath_career_univ_delete", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.log('Exception occurred at gpath_career_univDelete() and Exception:',err.message)
    }
  }
}

export {
  GPathCareerUnivs
}
