/*eslint-disable */
<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12">
        <iq-card>
          <template v-slot:headerTitle v-if="!propOpenedInModal">
            <h4 class="card-title">{{ cvCardTitle }}</h4>
          </template>
          <template v-slot:body>
            <p v-if="!propOpenedInModal">{{ cvCardSubHeader }}</p>
            <form action="#">
              <div class="form-row">

                <div class="col-md-12 mb-3">
                  <label for="validationname">
                    {{cvNameLabel}}</label
                  >
                  <input
                    v-model="vmGPathCareerUnivFormData.name"
                    type="text"
                    class="form-control"
                    required
                  />
              </div>
                <div class="col-md-12 mb-3">
                  <label for="validationcareer_univ_key">
                    {{cvCareerUnivKeyLabel}}</label
                  >
                  <input
                    v-model="vmGPathCareerUnivFormData.career_univ_key"
                    type="text"
                    class="form-control"
                    required
                  />
              </div>
                <div class="col-md-12 mb-3">
                  <label for="validationcountry">
                    {{cvCountryLabel}}</label
                  >
                  <input
                    v-model="vmGPathCareerUnivFormData.country"
                    type="text"
                    class="form-control"
                    required
                  />
              </div>
                <div class="col-md-12 mb-3">
                  <label for="validationuniv_name">
                    {{cvUnivNameLabel}}</label
                  >
                  <input
                    v-model="vmGPathCareerUnivFormData.univ_name"
                    type="text"
                    class="form-control"
                    required
                  />
              </div>
                <div class="col-md-12 mb-3">
                  <label for="validationuniv_url">
                    {{cvUnivUrlLabel}}</label
                  >
                  <input
                    v-model="vmGPathCareerUnivFormData.univ_url"
                    type="text"
                    class="form-control"
                    required
                  />
              </div>
                <div class="col-md-12 mb-3">
                  <label for="validationdetail">
                    {{cvDetailLabel}}</label
                  >
                  <textarea
                    v-model="vmGPathCareerUnivFormData.detail"
                    type="text"
                    class="form-control textarea"
                    required></textarea>
              </div>
                <div class="col-md-12 mb-3">
                  <label for="validationuniv_qr_image">
                    {{cvUnivQrImageLabel}}</label
                  >
                  <input
                    v-model="vmGPathCareerUnivFormData.univ_qr_image"
                    type="text"
                    class="form-control"
                    required
                  />
              </div>
              </div>
              <div class="form-group">
                <button v-if="cvLoadingStatus" type="button" class="btn btn-primary" :disabled="cvLoadingStatus">
                   <b-spinner label="Spinning"></b-spinner>
                </button>
                <button
                  type="button"
                  class="btn btn-primary"
                  @click="gpath_career_univAdd()"
                >
                  {{ cvSubmitBtn }}
                </button>
              </div>
            </form>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-toast
      v-model="showToast"
      name="Toast"
      :auto-hide-delay="toastVariant === 'danger' ? 10000 : 1000"
      :variant="toastVariant"
      :title="toastTitle"
    >
      <div v-html="toastMsg"></div>
    </b-toast>
  </b-container>
</template>

<script>
import { GPathCareerUnivs } from "../../../FackApi/api/GPathCareerUniv"
import ApiResponse from "../../../Utils/apiResponse"
import { socialvue } from "../../../config/pluginInit"
import userPermission from "../../../Utils/user_permission"

export default {
  name: "GPathCareerUnivAdd",
  props: {
    propOpenedInModal: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      apiName: "gpath_career_univ_add",
      cvCardTitle: "Add GPaths Career Univ",
      cvCardSubHeader: "Add GPaths Career Univ",
      cvSubmitBtn: "Add",
      cvNameLabel: "name",
      cvCareerUnivKeyLabel: "career univ key",
      cvCountryLabel: "country",
      cvUnivNameLabel: "univ name",
      cvUnivUrlLabel: "univ url",
      cvDetailLabel: "detail",
      cvUnivQrImageLabel: "univ qr image",
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      cvLoadingStatus: false,
      toastTitle: "GPathCareerUniv Add Response",
      vmGPathCareerUnivFormData: Object.assign({}, this.initFormData()),
      vmGPathCareerUnivDesc: null,
      vmGPathCareerUnivType: null
    }
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    }
  },
  beforeMount () {
    // Auth Access for Current User Role
    if (!userPermission(this, this.userData, this.apiName)) {
      this.$router.back()
    }
  },
  mounted () {
    socialvue.index()
  },
  methods: {
    /**
     * initFormData
     */
    initFormData () {
      return {
        "name": "",
        "career_univ_key": "",
        "country": "",
        "univ_name": "",
        "univ_url": "",
        "detail": "",
        "univ_qr_image": ""
      }
    },
    /**
     * validate
     */
    validate () {
      try {
        for (let index in this.vmGPathCareerUnivFormData) {
          if (!this.vmGPathCareerUnivFormData[index]) {
            this.toastMsg = "Missing " + index.split("_").join(" ")
            this.toastVariant = "danger"
            this.showToast = true
            return false
          }
        }
        return true
      }
      catch (err) {
        console.error("Exception occurred at validate() and Exception:", err.message)
      }
    },
    /**
     * gpath_career_univAdd
     */
    async gpath_career_univAdd () {
      // if (!this.validate()) {
      //   return false
      // }
      try {
        this.cvLoadingStatus = true
        let gpathCareerUnivAddResp = await GPathCareerUnivs.gpath_career_univAdd(this, this.vmGPathCareerUnivFormData)
        await ApiResponse.responseMessageDisplay(this, gpathCareerUnivAddResp)
        if (gpathCareerUnivAddResp && !gpathCareerUnivAddResp.resp_status) {
          return false
        }
        //  Emit if opened in Modal
        if (this.propOpenedInModal) {
          this.$emit("emitCloseGPathCareerUnivAddModal", gpathCareerUnivAddResp.resp_data)
        }
        this.vmGPathCareerUnivFormData = Object.assign({}, this.initFormData())
      }
      catch (err) {
        console.error("Exception occurred at gpath_career_univAdd() and Exception:", err.message)
      }
      finally {
        this.cvLoadingStatus = false
      }
    }
  }
}
</script>
<style scoped>
 textarea{
    height: 195px !important;
    overflow-y: scroll;
    line-height: 18px !important;
  }
</style>
